import ApolloClient from "apollo-boost";
import fetch from "isomorphic-fetch";
import gql from "graphql-tag";

import { getUser } from "@services/auth";

const API_URL = process.env.API_URL;

export const client = new ApolloClient({
  request: (operation) => {
    const token = getUser().token;
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    });
  },
  uri: `${API_URL}/graphql`,
  fetch,
  resolvers: {
    Query: {
      user: () => {
        return "haha";
      },
    },
  },
  typeDefs: gql`
    extend type Query {
      user: String!
    }
  `,
});
