import * as cookie from "js-cookie";

export const isBrowser = () => typeof window !== "undefined";

const cacheName = "im90sUser";

// export const getUser = () =>
//   isBrowser() && window.localStorage.getItem(cacheName)
//     ? JSON.parse(window.localStorage.getItem(cacheName))
//     : {};
export const getUser = () =>
  isBrowser() && cookie.get(cacheName) ? JSON.parse(cookie.get(cacheName)) : {};

const setUser = user => cookie.set(cacheName, JSON.stringify(user));
// window.localStorage.setItem(cacheName, JSON.stringify(user));

export const handleLogin = res => {
  const {
    jwt,
    user: { id, username }
  } = res.login ? res.login : res.register;

  if (jwt) {
    return setUser({
      username,
      id,
      token: jwt
    });
  }

  return false;
};

export const isLoggedIn = () => {
  const user = getUser();

  return !!user.username;
};

export const logout = callback => {
  setUser({});
  callback();
};
